import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import cleanDeep from 'clean-deep';

import { Payment } from '.';

const PaymentStore = types
  .model('PaymentStore', {
    data: types.maybeNull(Payment),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error'])),
    errorMessage: types.maybeNull(types.string)
  })

  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    }
  }))

  .actions(self => ({
    fetch(token) {
      self.setState('pending');

      return instance.get(`/api/payments/${token}`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    create(values = {}) {
      self.setState('pending');

      const data = cleanDeep(values, { emptyArrays: false });

      return instance.post('/api/payments', { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    register(token) {
      self.setState('pending');

      return instance.post(`/api/payments/${token}/register`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      let errorMessage = 'Произошла ошибка. Попробуйте снова';

      if (error?.response?.status === 429) {
        errorMessage = 'Платеж с таким номером уже существует';
      } else if (error?.response?.status === 422) {
        const messages = error?.response?.data?.messages;
        if (messages && messages.length > 0) {
          const numberError = messages.find(message =>
            message?.path?.includes('number') ||
            (message?.message && message.message.includes('Number must be uniq'))
          );
          if (numberError) {
            errorMessage = 'Платеж с таким номером уже существует';
          } else {
            errorMessage = messages.map(message => message.message || message).join('\n');
          }
        }
      }

      self.setErrorMessage(errorMessage);
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
      self.setState(null);
    },

    setErrorMessage(message) {
      self.errorMessage = message;
    }
  }));

export default PaymentStore;
