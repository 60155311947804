import { t } from 'utils/localization';

const fields = [
  'number',
  'description',
  'first_name',
  'middle_name',
  'last_name',
  'phone',
  'email',
  'amount',
  'traveller',
  'traveller.first_name',
  'traveller.last_name',
  'traveller.middle_name',
  'acquiring_provider'
];

const rules = {
  number: 'required',
  description: 'required',
  // email: 'required',
  phone: 'required',
  amount: 'required',
  traveller: 'required',
  'traveller.first_name': 'required',
  'traveller.last_name': 'required',
  'acquiring_provider': 'required'
};

const labels = {
  number: t('Form.OrderCode.Label'),
  description: t('Form.Description.Label'),
  phone: t('Form.Phone.Label'),
  email: t('Form.Email.Label'),
  amount: t('Booking.Form.Amount.Label'),
  traveller: t('Booking.Form.Traveller.Label'),
  acquiring_provider: t('Booking.Form.AcquiringProvider.Label')
};

const placeholders = {
  number: t('Form.OrderCode.Placeholder'),
  'traveller.first_name': t('Form.FirstName.Label'),
  'traveller.middle_name': t('Form.MiddleName.Label'),
  'traveller.last_name': t('Form.LastName.Label')
};

const input = {
  number: value => value.toUpperCase()
};

const extra = {
  'acquiring_provider': [
    { value: 'gazprombank', label: t('Booking.Form.AcquiringProvider.Gazprombank') },
    { value: 'sberbank', label: t('Booking.Form.AcquiringProvider.Sberbank') },
    { value: 'sber_sbp', label: t('Booking.Form.AcquiringProvider.SberSbp') },
    { value: 'mir_cashback', label: t('Booking.Form.AcquiringProvider.MirCashback') }
  ]
}

export default {
  fields,
  rules,
  labels,
  placeholders,
  input,
  extra
};
