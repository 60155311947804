import { types } from 'mobx-state-tree';

import { Traveller } from 'stores/models';

const Payment = types.model('Payment', {
  id: types.identifier,
  amount: types.number,
  description: types.string,
  number: types.string,
  phone: types.string,
  email: types.maybeNull(types.string),
  traveller: types.maybeNull(Traveller),
  form_url: types.maybeNull(types.string),
  payment_url: types.maybeNull(types.string),
  status: types.maybeNull(types.number),
  token: types.string,
  acquiring_provider: types.enumeration(['gazprombank', 'sberbank', 'sber_sbp', 'mir_cashback'])
});

export default Payment;
