export default {
  translation: {
    Booking: {
      Title: 'Новое бронирование',
      Form: {
        Title: 'Данные заказа',
        Traveller: {
          Label: 'ФИО плательщика'
        },
        Amount: {
          Label: 'Сумма к оплате'
        },
        AcquiringProvider: {
          Label: 'Способ оплаты',
          Sberbank: 'Сбербанк',
          Gazprombank: 'Газпромбанк',
          MirCashback: 'МИР Кэшбэк',
          SberSbp: 'СБП'
        },
        Submit: 'Сформировать',
        PendingSubmit: 'Формируем платёж',
        PaymentLink: {
          Title: 'Ссылка на оплату сформирована:'
        },
        Errors: {
          General: 'Произошла ошибка при создании платежа. Проверьте правильность введённых данных',
          Validation: 'Заполните недостающие поля'
        }
      },
      Details: {
        Title: 'К оплате',
        Data: {
          Title: 'Данные заказа'
        },
        Pending: 'Получаем детали платежа'
      },
      Statuses: {
        AuthorizationCancelled: 'Авторизация отменена',
        AuthorizationDeclined: 'Авторизация отклонена',
        AuthorizationHold: 'Предавторизованная сумма захолдирована',
        ACSAuthorizationInit: 'Инициирована авторизация через ACS банка-эмитента',
        Cash: 'Оплата наличными при заселении',
        RegistrationError: 'Ошибка регистрации платежа',
        PaymentSuccess: 'Заказ оплачен. Свяжемся с Вами в ближайшее время',
        TransactionRefunded: 'По транзакции была проведена операция возврата'
      },
      Success: {
        Title: 'Оплачено'
      },
      Errors: {
        Fetching: {
          Title: 'Данные платежа не найдены',
          Description: 'К сожалению, мы не смогли получить данные вашего платежа. Возможно, ссылка на него устарела или возникли непредвиденные трудности. Попробуйте зайти позднее или обратитесь в службу поддержки'
        }
      }
    },
    Form: {
      Description: {
        Label: 'Описание'
      },
      FirstName: {
        Label: 'Имя',
      },
      MiddleName: {
        Label: 'Отчество'
      },
      LastName: {
        Label: 'Фамилия'
      },
      OrderCode: {
        Label: 'Заказ №',
        Placeholder: 'ABC-12-12345-9'
      },
      Phone: {
        Label: 'Телефон'
      },
      Email: {
        Label: 'E-mail'
      }
    },
    UI: {
      Copy: 'Скопировать',
      LinkCopied: 'Ссылка скопирована в буфер',
      Pay: 'Оплатить',
      Price: '{price, number, RUB}'
    }
  }
};
