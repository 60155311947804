import React from 'react';
import { withTranslation } from 'react-i18next';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form } from 'components/ui';
import { PaymentTemplate } from 'pages/Payments/components';
import { PaymentLink, PaymentSubmit, PhoneField, EmailField, TextField, Traveller, SelectField } from '.';

import { typography } from 'theme/mixins';

import { inputMask } from 'utils/mask';

const Wrapper = styled.div``;

const Header = styled.header`
  padding: 24px 0 8px;
  ${typography('h2')}
`;

const Body = styled(PaymentTemplate.Body)``;

const Footer = styled(PaymentTemplate.Footer)`
  margin-top: 16px;
`;

@inject('paymentsForm', 'paymentStore')
@observer
class PaymentsForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    paymentsForm: PropTypes.object.isRequired,
    paymentStore: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isPending() {
    const { paymentStore } = this.props;
    return paymentStore.isPending;
  }

  @computed get hasValidationError() {
    const { paymentsForm } = this.props;
    return !paymentsForm.isValid && paymentsForm.submitted > 0;
  }

  @computed get hasApiError() {
    const { paymentStore } = this.props;
    return paymentStore.isError;
  }

  @computed get payment() {
    const { paymentStore } = this.props;
    return paymentStore.data?.toJSON() || {};
  }

  render() {
    const { paymentsForm, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Form form={paymentsForm}>
          <Header>
            {t('Booking.Form.Title')}
          </Header>

          <Body>
            <TextField
              autoFocus
              field={paymentsForm.$('number')}
              disabled={this.isPending}
              {...inputMask.orderCode()}
            />

            <TextField
              field={paymentsForm.$('description')}
              disabled={this.isPending}
              rows={3}
            />

            <Traveller
              disabled={this.isPending}
              traveller={paymentsForm.$('traveller')}
            />

            <PhoneField
              disabled={this.isPending}
              field={paymentsForm.$('phone')}
            />

            <EmailField
              disabled={this.isPending}
              field={paymentsForm.$('email')}
            />

            <TextField
              field={paymentsForm.$('amount')}
              disabled={this.isPending}
              maxLength="6"
              {...inputMask.number()}
            />

            <SelectField
              field={paymentsForm.$('acquiring_provider')}
              disabled={this.isPending}
            />

            {this.hasValidationError && (
              <Form.Error error={t('Booking.Form.Errors.Validation')} />
            )}

            {this.hasApiError && (
              <Form.Error error={this.props.paymentStore.errorMessage} />
            )}
          </Body>

          <Footer>
            {this.payment.payment_url
              ? (
                <PaymentLink payment_url={this.payment.payment_url} />
              )
              : (
                <PaymentSubmit
                  isPending={this.isPending && !this.hasApiError}
                  amount={+paymentsForm.$('amount').value}
                />
              )}
          </Footer>
        </Form>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentsForm))``;
