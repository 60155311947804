import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Provider } from 'mobx-react';

import { PaymentTemplate } from 'pages/Payments/components';
import { Form } from '.';

import { PaymentsForm } from 'forms';
import { One as PaymentStore } from 'stores/PaymentsStore';

class Add extends React.Component {
  static propTypes = {
    t: PropTypes.func
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.paymentsForm = new PaymentsForm({}, { hooks });
    this.paymentStore = PaymentStore.create();
  }

  handleSuccess = (form) => {
    const values = form.values();
    this.paymentStore.create(values);
    this.paymentStore.clear();
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  render() {
    const { t } = this.props;

    return (
      <Provider
        paymentsForm={this.paymentsForm}
        paymentStore={this.paymentStore}
      >
        <PaymentTemplate>
          <PaymentTemplate.Header>
            {t('Booking.Title')}
          </PaymentTemplate.Header>

          <Form />
        </PaymentTemplate>
      </Provider>
    );
  }
}

export default withTranslation()(Add);
